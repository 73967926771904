.root-container {
    min-height: 97vh;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;

    row-gap: 10px;
}

.tables-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 30px;
}

.text-container {
    max-width: 600px;
}

.notification-error {
    color: red;
    margin-right: 5px;
}

.notification-confirm {
    color: green;
    margin-right: 5px;
}

.menu-container {
    position: sticky;
    top: 3px;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

    row-gap: 2px;

    background-color: white;
}

.stats-container {
    display: flex;
    flex-direction: column;

    max-width: 40vw;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 2px;
}

.s-button-container {
    display: flex;
    flex-wrap: wrap;

    row-gap: 2px;
    margin-bottom: 10px;
}

.single-entry-container {
    display: flex;
    flex-wrap: wrap;

    column-gap: 5px;
    row-gap: 10px;
}

.single-entry-field {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: flex-end;
}

.dark-button {
    background-color: darkblue;
    color: white;
    border-style: solid;
    border-radius: 3px;
    border-width: 2px;
    border-color: black;
}

.dark-button:hover {
    background-color: darkslateblue;
}

.unsaved {
    color: red;
}

.saved{
    color: green;
}

.untracked{
    opacity: .65;
}

.no-boader-head {
    font-size: 18px;
    padding-right: 10px;
}

.boader-head {
    padding-right: 10px;
    font-size: 18px;
    border-bottom-style: double;
}

button {
    margin-left: 2px;
}

table {
    border-collapse: collapse;
}

th {
    padding-right: 15px;
    text-align: left;
}

td {
    padding: 4px;
    text-align: left;
}

tr:nth-child(even){
    background-color: #f2f2f2;
}

td:nth-child(1) {
    font-weight: bolder;
}

footer {
    display: flex;
    justify-content: center;

    font-size: 14px;
    font-style: italic;
    padding: 0px;
    margin-top: 20px;

    background-color: white;
}